

.glitch.text.twitch-effect{

        color: white;
        position: relative;
        margin: 0 auto;
        background: rgba(0,0,0,0.95);
        letter-spacing: 1px;
        margin: auto;
        font-size: 120px;
        text-transform: uppercase;

        &:before {
          content:attr(data-text);
          position:absolute;
          left:-2px;
          text-shadow:1px 0 blue;
          top:0;
          color:white;
          background:rgba(0,0,0,0.25);
          overflow:hidden;
        }

        &:after {
          content:attr(data-text);
          position:absolute;
          left:2px;
          text-shadow:-1px 0 red;
          top:0;
          color:white;
          background:black;
          overflow:hidden;

        }

  &:not(.hover) {
      &.few{
        &:after{
          clip:rect(0,900px,0,0);
          animation:glitchTextNoiseMono_before_few 2s infinite linear alternate-reverse;
        }
        &:before{
          clip:rect(0,900px,0,0);
          animation:glitchTextNoiseMono_after_few  2s infinite linear alternate-reverse;
        }
      }
      &.medium{
          animation: glitchTextNoiseMono_text_medium 5.0s infinite;
        &:after{
          clip:rect(0,900px,0,0);
          animation:glitchTextNoiseMono_before_medium 2s infinite linear alternate-reverse;
        }
        &:before{
          clip:rect(0,900px,0,0);
          animation:glitchTextNoiseMono_after_medium 2s infinite linear alternate-reverse;
        }
      }
      &.hard{
        animation: glitchTextNoiseMono_text_hard 5.0s infinite;
        &:after{
          clip:rect(0,900px,0,0);
          animation:glitchTextNoiseMono_before_hard 2s infinite linear alternate-reverse;
        }
        &:before{
          clip:rect(0,900px,0,0);
          animation:glitchTextNoiseMono_after_hard 2s infinite linear alternate-reverse;
        }
      }

}

&.hover{
  &:hover {

    &.few{
      &:after{
        clip:rect(0,900px,0,0);
        animation:glitchTextNoiseMono_before_few 2s infinite linear alternate-reverse;
      }
      &:before{
        clip:rect(0,900px,0,0);
        animation:glitchTextNoiseMono_after_few  2s infinite linear alternate-reverse;
      }
    }
    &.medium{
        animation: glitchTextNoiseMono_text_medium 5.0s infinite;
      &:after{
        clip:rect(0,900px,0,0);
        animation:glitchTextNoiseMono_before_medium 2s infinite linear alternate-reverse;
      }
      &:before{
        clip:rect(0,900px,0,0);
        animation:glitchTextNoiseMono_after_medium 2s infinite linear alternate-reverse;
      }
    }
    &.hard{
      animation: glitchTextNoiseMono_text_hard 5.0s infinite;
      &:after{
        clip:rect(0,900px,0,0);
        animation:glitchTextNoiseMono_before_hard 2s infinite linear alternate-reverse;
      }
      &:before{
        clip:rect(0,900px,0,0);
        animation:glitchTextNoiseMono_after_hard 2s infinite linear alternate-reverse;
      }
    }

  }
}



}
