@import '../assets/constant';
@import '../assets/keyframes';

.glitch.back.noise {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    height: 200px;
    background: #673ab7;
    color: #fff;
    text-align: center;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        // opacity: 0.1;
        background: url('../../src/img/img-noise.png');
        animation: 1s infinite glitchBackgroundNoise;
        pointer-events: none;
    }
}
