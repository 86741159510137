@import 'constant';
//==========back noise========
@keyframes glitchBackgroundNoise{
  @for $i from 0 through $count{
    #{percentage($i*(1/$count))}{
      background-position: random(1000)+px random(1000)+px;
    }
  }
}
//==========text glitchTextNoiseMono========
@keyframes glitchTextNoiseMono_before_few{

  @for $i from 0 through $count{
    #{percentage($i*(1/$count))}{
      clip:rect(random(100)+px,9999px,random(100)+px,0);
    }
  }
}

@keyframes glitchTextNoiseMono_after_few{
  @for $i from 0 through $count{
    #{percentage($i*(1/$count))}{
      clip:rect(random(100)+px,9999px,random(100)+px,0);
    }
  }
}


@keyframes glitchTextNoiseMono_before_medium{
  @for $i from 0 through $count{
    #{percentage($i*(1/$count))}{
            clip: rect(random(150)+px, 9000px, random(150)+px,random(10)+px)
    }
  }
}

@keyframes glitchTextNoiseMono_after_medium{
  @for $i from 0 through $count{
    #{percentage($i*(1/$count))}{
            clip: rect(random(150)+px, 9000px, random(150)+px, random(10)+px)
    }
  }
}
@keyframes glitchTextNoiseMono_text_medium{



  84%{ transform: rotateX(0deg) skewX(0deg); }
  85%{  transform: rotateX(10deg) skewX(50deg); }
  86%{ transform: rotateX(0deg) skewX(0deg); }
}

@keyframes glitchTextNoiseMono_before_hard{
  @for $i from 0 through $count{
    #{percentage($i*(1/$count))}{
            clip: rect(random(150)+px, 9000px, random(150)+px,random(10)+px)
    }
  }
}
@keyframes glitchTextNoiseMono_after_hard{
  @for $i from 0 through $count{
    #{percentage($i*(1/$count))}{
            clip: rect(random(150)+px, 9000px, random(150)+px, random(10)+px)
    }
  }
}

@keyframes glitchTextNoiseMono_text_hard{

  32%{ opacity: 1;}
  33%{ opacity: 0; }
  34%{ opacity: 1;}

  39%{ opacity: 1;}
  40%{ opacity: 0; }
  41%{ opacity: 1;}



    46%{ transform: rotateX(0deg) skewX(0deg); }
    47%{  transform: rotateX(0deg) skewX(-90deg); }
    48%{ transform: rotateX(0deg) skewX(0deg); }

    65%{ opacity: 1;}
    66%{ opacity: 0; }
    67%{ opacity: 1;}

  84%{ transform: rotateX(0deg) skewX(0deg); }
  85%{  transform: rotateX(10deg) skewX(50deg); }
  86%{ transform: rotateX(0deg) skewX(0deg); }
}




//==========text glitchTextNoise========

@keyframes glitchTextNoise_before_few {
  0% { clip: rect(36px, 9999px, 9px, 0)  }
  25% { clip: rect(25px, 9999px, 99px, 0) }
  50% { clip: rect(50px, 9999px, 102px, 0) }
  75% { clip: rect(30px, 9999px, 92px, 0) }
  100% { clip: rect(91px, 9999px, 98px, 0) }
}

@keyframes glitchTextNoise_after_few {
  0% { top: -1px; left: 1px; clip: rect(65px, 9999px, 119px, 0) }
  25% { top: -6px; left: 4px; clip: rect(79px, 9999px, 19px, 0) }
  50% { top: -3px; left: 2px; clip: rect(68px, 9999px, 11px, 0) }
  75% { top: 0px; left: -4px; clip: rect(95px, 9999px, 53px, 0) }
  100% { top: -1px; left: -1px; clip: rect(31px, 9999px, 149px, 0) }
}



@keyframes glitchTextNoise_before_medium {
  0% { clip: rect(36px, 9999px, 9px, 0)  }
  25% { clip: rect(25px, 9999px, 99px, 0) }
  50% { clip: rect(50px, 9999px, 102px, 0) }
  75% { clip: rect(30px, 9999px, 92px, 0) }
  100% { clip: rect(91px, 9999px, 98px, 0) }
}
@keyframes glitchTextNoise_after_medium {
  0% { top: -1px; left: 1px; clip: rect(65px, 9999px, 119px, 0) }
  25% { top: -6px; left: 4px; clip: rect(79px, 9999px, 19px, 0) }
  50% { top: -3px; left: 2px; clip: rect(68px, 9999px, 11px, 0) }
  75% { top: 0px; left: -4px; clip: rect(95px, 9999px, 53px, 0) }
  100% { top: -1px; left: -1px; clip: rect(31px, 9999px, 149px, 0) }
}
@keyframes glitchTextNoise_text_medium{

  32%{ opacity: 1;}
  33%{ opacity: 0.5; }
  35%{ opacity: 1;}

  39%{ opacity: 1;}
  40%{ opacity: 0.5; }
  41%{ opacity: 1;}

  65%{ opacity: 1;}
  66%{ opacity: 0.5; }
  67%{ opacity: 1;}

  84%{ transform: rotateX(0deg) skewX(0deg); }
  85%{  transform: rotateX(10deg) skewX(50deg); }
  86%{ transform: rotateX(0deg) skewX(0deg); }
}


@keyframes glitchTextNoise_before_hard {
  0% { clip: rect(36px, 9999px, 9px, 0)  }
  25% { clip: rect(25px, 9999px, 99px, 0) }
  50% { clip: rect(50px, 9999px, 102px, 0) }
  75% { clip: rect(30px, 9999px, 92px, 0) }
  100% { clip: rect(91px, 9999px, 98px, 0) }
}
@keyframes glitchTextNoise_after_hard {
  0% { top: -1px; left: 1px; clip: rect(65px, 9999px, 119px, 0) }
  25% { top: -6px; left: 4px; clip: rect(79px, 9999px, 19px, 0) }
  50% { top: -3px; left: 2px; clip: rect(68px, 9999px, 11px, 0) }
  75% { top: 0px; left: -4px; clip: rect(95px, 9999px, 53px, 0) }
  100% { top: -1px; left: -1px; clip: rect(31px, 9999px, 149px, 0) }
}
@keyframes glitchTextNoise_text_hard{
  32%{ opacity: 1;}
  33%{ opacity: 0.5; }
  35%{ opacity: 1;}

  39%{ opacity: 1;}
  40%{ opacity: 0.2; }
  41%{ opacity: 1;}


  46%{ transform: rotateX(0deg) skewX(0deg); }
  47%{  transform: rotateX(0deg) skewX(-90deg); }
  48%{ transform: rotateX(0deg) skewX(0deg); }

  65%{ opacity: 1;}
  66%{ opacity: 0.5; }
  67%{ opacity: 1;}

  84%{ transform: rotateX(0deg) skewX(0deg); }
  85%{  transform: rotateX(10deg) skewX(70deg); }
  86%{ transform: rotateX(0deg) skewX(0deg); }
}


//==========text glitchTextCrash========

@keyframes glitchTextCrash_few {
 0% { transform: translate(0) }
 20% { transform: translate(-3px, 3px) }
 40% { transform: translate(-3px, -3px) }
 60% { transform: translate( 3px, 3px) }
 80% { transform: translate( 3px, -3px) }
 to { transform: translate(0) }
}

@keyframes glitchTextCrash_medium {
 0% { transform: translate(0) }
 20% { transform: translate(-4px, 4px) }
 40% { transform: translate(-4px, -4px) }
 60% { transform: translate(4px, 4px) }
 80% { transform: translate(4px, -4px) }
 to { transform: translate(0) }
}

@keyframes glitchTextCrash_hard {
 0% { transform: translate(0) }
 20% { transform: translate(-5px, 5px) }
 40% { transform: translate(-5px, -5px) }
 60% { transform: translate(5px, 5px) }
 80% { transform: translate(5px, -5px) }
 to { transform: translate(0) }
}
