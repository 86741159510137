//основные стили
@import 'assets/globalStyles';

//стили фона
@import 'glitch_back/noiseBackgroundImage';

//стили фона
@import 'glitch_text/glitchTextNoise';
@import 'glitch_text/glitchTextNoiseMono';
@import 'glitch_text/glitchTextCrash';
@import 'glitch_text/glitchSplitting';
